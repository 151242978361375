import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { InspirationData } from '../InspirationPath';
import { AiOutlineClose } from 'react-icons/ai';
import '../styles.css'
import { FidgetSpinner } from 'react-loader-spinner'







export default function InspirationModal({ openInspirationModal, handleCloseInspirationModal, }) {

    const [imageLoaders, setImageLoaders] = useState(InspirationData.map(() => true));

    const handleImageLoad = (index) => {
        setImageLoaders(prevLoaders => prevLoaders.map((loader, i) => (i === index ? false : loader)));
    };


    const handleHumansImageSelected = () => {
        handleCloseInspirationModal()

    }

    return (

        <Modal
            open={openInspirationModal}
            onClose={handleHumansImageSelected}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                overflow: 'scroll'
            }}

        >

            <Box
                // className='text-modal'
                style={{

                    borderRadius: '20px',
                    backgroundColor: 'white'

                }}

                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxHeight: '80%',
                    overflow: 'scroll',
                    boxShadow: 24,
                    p: 4,

                }}



            >

                <div className='humansimagesmodal'>
                    <AiOutlineClose
                        className='cancel-modal'
                        color='#36454F'
                        onClick={handleCloseInspirationModal} />


                    <div



                        className='InpsirationModalText'
                    >

                        <div
                            style={{ fontWeight: 'bold', textAlign: 'center', padding: '0.5rem' }}
                        >Immerse yourself in the cool designs of our awesome customers</div>

                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            justifyContent: 'center',

                        }}>


                            {
                                InspirationData.map((each_image, index) => {


                                    return (

                                        <div key={index}>
                                            {
                                                imageLoaders[index] && (
                                                    <div className='spinner'>
                                                        <FidgetSpinner />
                                                    </div>
                                                )
                                            }

                                            <img
                                                src={each_image.imgsrc}
                                                onLoad={() => handleImageLoad(index)}
                                                className='inspirationImages'
                                                key={index}
                                                draggable={false}

                                            />

                                        </div>

                                    )
                                })
                            }

                        </div>
                    </div>




                </div>
            </Box>

        </Modal >

    )
}
