import mosaic1 from '../../src/Humaaans/mosaic.jpg'
import mosaic2 from '../../src/Humaaans/mosaic.png'
import mosaic3 from '../../src/Humaaans/mosaic2.jpg'
import mosaic4 from '../../src/Humaaans/mosaic3.jpg'
import mosaic5 from '../../src/Humaaans/mosaic5.jpg'
import mosaic6 from '../../src/Humaaans/mosaic6.jpg'
import compo1 from '../../src/Humaaans/compo.jpg'
import python from '../../src/Humaaans/python.png'
import js from '../../src/Humaaans/JavaScript.png'

import mojimodel1 from '../assets/shirtPics/15.webp'
import mojimodel2 from '../assets/shirtPics/31.webp'
import mojimodel3 from '../assets/shirtPics/25.webp'
import mojimodel4 from '../assets/shirtPics/41.webp'
import mojimodel5 from '../assets/shirtPics/28.webp'
import mojimodel6 from '../assets/shirtPics/22.webp'

import aimodel1 from '../assets/shirtPics/5.webp'
import aimodel2 from '../assets/shirtPics/48.webp'
import aimodel3 from '../assets/shirtPics/40.webp'
import aimodel4 from '../assets/shirtPics/19.webp'
import aimodel5 from '../assets/shirtPics/6.webp'
import aimodel6 from '../assets/shirtPics/12.webp'

import techmodel1 from '../assets/shirtPics/45.webp'
import techmodel2 from '../assets/shirtPics/32.webp'


export const EmojiData = [
    {
        id: 1,
        Name: "Cool Shadey",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F13.webp?alt=media&token=8d51cc0a-f7b8-4450-ba1b-5cccc7f588b8&_gl=1*155j1uo*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYwMzkuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F15.webp?alt=media&token=2b8da663-a707-439e-8b8c-8caf8af06718&_gl=1*1t9jvy1*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYwOTguMS4wLjA',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F17.webp?alt=media&token=cc7a9517-e79b-4df7-aa49-958a73e5a216&_gl=1*145pq21*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYxMjkuMzAuMC4w',

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵90',
        color: '#C68B8D',
        artWork: `😎`,
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Light Pink',
        decalColor: '',
        modelImg: mojimodel1


    },
    {
        id: 2,
        Name: "Enchanted",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F31.webp?alt=media&token=5de20d5a-92ec-4223-a684-3a7708eb28ca&_gl=1*zx2zhd*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYxODYuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F36.webp?alt=media&token=dbe80373-7950-4332-b131-4c545f285a13&_gl=1*zmvg6v*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYyMDcuMzkuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F35.webp?alt=media&token=d7989b7d-7658-4221-81f4-57280c486afb&_gl=1*1nzb9qv*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYyMzYuMTAuMC4w',

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵90',
        color: '#62605E',
        artWork: `😍`,
        text: '',
        artposition: 'Left Chest',
        colorNameInEnglish: 'Ash',
        decalColor: '',
        modelImg: mojimodel2

    },

    {
        id: 3,
        Name: "Frozen",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F27.webp?alt=media&token=dea59731-470e-464d-bdca-b140088a4ebc&_gl=1*1w0fbys*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYyOTQuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F25.webp?alt=media&token=fb9b3ca8-c2d9-4307-9ba3-3f500a5dff57&_gl=1*1oicfw5*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYzMjguMjYuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F26.webp?alt=media&token=3e06c8bc-7833-4cca-857b-46e19d4d543f&_gl=1*zcwk5j*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTYzNjQuNTcuMC4w',

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        Price: '₵90',
        color: '#DE0228',
        artWork: `🥶`,
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Red',
        decalColor: 'black',
        modelImg: mojimodel3


    },

    {
        id: 4,
        Name: "Web Developer",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F41.webp?alt=media&token=450f80bf-f339-4f66-9436-0f2fed474548&_gl=1*pmxu44*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY0MjAuMS4wLjA',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F39.webp?alt=media&token=81772923-59c3-46f2-a99a-575ddfc2d285&_gl=1*1yroqjo*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY0NTMuMzAuMC4w',
        ],
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing',
        Price: '₵90',
        color: '#68A0D0',
        artWork: `🕸️`,
        text: 'Developer',
        artposition: 'Large Front',
        colorNameInEnglish: 'Sea Blue',
        decalColor: '5C4337',
        modelImg: mojimodel4



    },
    {
        id: 5,
        Name: "No long Talk",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F28.webp?alt=media&token=284b9453-0198-4b7e-805f-051c04d9c8bc&_gl=1*1watbas*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY1MTYuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F29.webp?alt=media&token=840fccaf-84f5-4582-af9a-46a19f9a2a42&_gl=1*1mxmwdf*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY1NDcuMjkuMC4w',


        ],
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        Price: '₵90',
        color: '#71ACA0',
        artWork: `🤫`,
        text: 'NoLongTalk',
        artposition: 'Large Front',
        colorNameInEnglish: 'Light Green',
        decalColor: '',
        modelImg: mojimodel5


    },

    {
        id: 6,
        Name: "I Dey Watch",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F22.webp?alt=media&token=66a1d959-bd07-4f03-addd-419eb081f2c1&_gl=1*1f1alg2*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY2MTAuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F23.webp?alt=media&token=a1008ec6-a030-4218-bc67-a904bc8dd63f&_gl=1*1y2fncy*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY2MzAuNDAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F24.webp?alt=media&token=45596176-03ee-44e2-b892-104629113dd7&_gl=1*1ifo3gi*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY2NDkuMjEuMC4w',

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
        Price: '₵90',
        color: '#68A0D0',
        artWork: `🫣`,
        text: 'ideywatch',
        artposition: 'Large Front',
        colorNameInEnglish: 'Light Blue',
        decalColor: '',
        modelImg: mojimodel6



    },

    // {
    //     id: 7,
    //     Name: "Name of Shirt",
    //     images: [
    //         'https://images.unsplash.com/photo-1613852348851-df1739db8201?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1287&q=80',
    //         'https://images.unsplash.com/photo-1598522396013-924ddcd44ada?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=400&q=60',
    //         'https://images.unsplash.com/photo-1598522325484-118715b67eb6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=400&q=60',

    //     ],
    //     description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    //     Price: '₵90',
    //     color: '#DE0228',
    //     artWork: `🥶`,
    //     text: '',
    //     artposition: 'Large Front',
    //     colorNameInEnglish: 'Red',
    //     decalColor: '',


    // },
]






export const AIData = [
    {
        id: 1,
        Name: "Exquisite Visage",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F5.webp?alt=media&token=a8c007ec-0ec2-496a-8c9e-58a5f3925fb2&_gl=1*pwobpr*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY3MDMuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F4.webp?alt=media&token=9c93193d-6226-4ada-a485-c866fd380160&_gl=1*pn1bmc*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY3MzIuMzEuMC4w',
            "https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F3.webp?alt=media&token=98964f45-2e7b-4667-a5da-7b8a13a833b5&_gl=1*16j3ulo*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY4MDQuMjkuMC4w"

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#8B2638',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Wine',
        decalColor: '',
        AIArtWork: mosaic1,
        modelImg: aimodel1


    },

    {
        id: 2,
        Name: "Artsync Merge",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F48.webp?alt=media&token=ea291e8d-32cb-44df-9c49-d600cdd80750&_gl=1*1o0e14f*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY4NzQuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F47.webp?alt=media&token=6657067c-1abe-4ddb-a152-d854b7519565&_gl=1*fihxjc*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY5MDMuMzEuMC4w',
        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#ccc',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'White',
        decalColor: '',
        AIArtWork: compo1,
        modelImg: aimodel2

    },


    {
        id: 3,
        Name: "Scenery Merge",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F40.webp?alt=media&token=06f52d97-0313-431e-97dd-6e42711f8439&_gl=1*aecint*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTY5NzIuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F37.webp?alt=media&token=89d808eb-024b-4daf-8313-888e2cbdc9d5&_gl=1*dx1fcx*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTcwMzguNjAuMC4w',
        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#C68B8D',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Light Pink',
        decalColor: '',
        AIArtWork: mosaic4,
        modelImg: aimodel3


    },

    {
        id: 4,
        Name: "Iconmarks Mauve",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F18.webp?alt=media&token=3b23f9d0-2351-4f59-9587-d4c67e53d10a&_gl=1*1lwicfe*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTcxNDguNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F20.webp?alt=media&token=c0041a37-11fb-43a2-8cb7-b704c3189983&_gl=1*11shvgj*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTcyMjAuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F19.webp?alt=media&token=007b21a5-6518-4a97-a54b-aa5d4d1f3615&_gl=1*d1itoi*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTcyNTkuMjEuMC4w',

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#574891',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Mauve',
        decalColor: '',
        AIArtWork: mosaic6,
        modelImg: aimodel4


    },

    {
        id: 5,
        Name: "Urban Rhythmz",
        images: [
            // 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F8.webp?alt=media&token=cbb4f87a-32f0-45a5-aaa0-ae9b4bf0e7cb&_gl=1*1r7z18b*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NTk1MzUyMS42Mi4xLjE2OTU5NTQ1MDkuNDguMC4w',
            "https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F50.webp?alt=media&token=85d4f9f6-bb26-4252-9fd1-ff0e4a27840f&_gl=1*7qlcek*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5Nzc4NTA5Ny43MC4xLjE2OTc3ODUyMzMuNC4wLjA",
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F51.webp?alt=media&token=a1270f72-3a7f-47d3-a822-7b8a0efccc20&_gl=1*cntqp7*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5Nzc4NTA5Ny43MC4xLjE2OTc3ODUzNjYuNjAuMC4w',

            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F6.webp?alt=media&token=80842f2c-d9cd-41a4-880a-5ee70b2695d2&_gl=1*96y4ac*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NTk1MzUyMS42Mi4xLjE2OTU5NTQ0OTcuNjAuMC4w'

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#E2D9BC',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Cream',
        decalColor: '',
        AIArtWork: mosaic2,
        modelImg: aimodel5

    },
    {
        id: 6,
        Name: "Iconmarks Dark",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F11.webp?alt=media&token=e39492d7-d6ff-4056-a587-a67d282efbb5&_gl=1*fxxzr7*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NTk1MzUyMS42Mi4xLjE2OTU5NTQ4MjkuNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F12.webp?alt=media&token=463c10f0-4e1e-48e7-b527-a8729311d23e&_gl=1*1hut0iu*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NTk1MzUyMS42Mi4xLjE2OTU5NTQ4MzMuNTYuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F9.webp?alt=media&token=3122d284-d878-4380-a3f5-c5c4d3dbdd50&_gl=1*rqkfb0*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NTk1MzUyMS42Mi4xLjE2OTU5NTQ4NDguNDEuMC4w'

        ],
        description: 'Lorem, ipsum dolor sit amet consectetur',
        Price: '₵100',
        color: '#353934',
        text: '',
        artposition: 'Large Front',
        colorNameInEnglish: 'Black',
        decalColor: '',
        AIArtWork: mosaic4,
        modelImg: aimodel6



    },




]




export const TechData = [
    {
        id: 1,
        Name: "Console Me",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F45.webp?alt=media&token=44cf65b9-3c8f-4458-815c-632acb038fde&_gl=1*1kptm6m*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTc0NjguNjAuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F46.webp?alt=media&token=6074d9c3-b1f5-48d2-8620-7775cb6ed095&_gl=1*7fym5d*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTc1NTQuNjAuMC4w'


        ],
        Price: '₵90',
        color: '#ECB71C',
        text: 'ConsoleMe',
        artposition: 'Large Front',
        colorNameInEnglish: 'Yellow',
        decalColor: '060201',
        TechArtWork: js,
        modelImg: techmodel1

    },
    {
        id: 2,
        Name: "I don't Bite",
        images: [
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F32.webp?alt=media&token=d6127f99-327f-4e51-82af-27bd140a91ee&_gl=1*11krnby*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTc1ODcuMjcuMC4w',
            'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/MojiMages%2F42.webp?alt=media&token=53016ee8-9195-4d5e-b962-d0d7a0b04afc&_gl=1*1223zdv*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzE1NTYxMS42Ni4xLjE2OTcxNTc2MTQuNjAuMC4w',
        ],
        Price: '₵90',
        color: '#353934',
        text: 'idontBite',
        artposition: 'Large Front',
        colorNameInEnglish: 'Black',
        decalColor: 'FFFFFF',
        TechArtWork: python,
        modelImg: techmodel2


    },





]