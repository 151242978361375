
export const InspirationData = [

    {
        id: 1,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F4.jpg?alt=media&token=a90fc5bc-1aaf-4e47-9427-e04998987b03'
    },
    {
        id: 2,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F1.JPG?alt=media&token=435abd06-5f12-4f4a-b594-2b77e42d6c80'
    },

    {
        id: 2.1,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F2.jpeg?alt=media&token=7139e0b8-c318-411a-bf47-be657f318601'
    },

    {
        id: 2.2,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F2b.jpeg?alt=media&token=44d71c47-d5d6-4cfe-86a8-30f2930d8e7c'
    },

    {
        id: 3,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F2.jpg?alt=media&token=acfda6b3-9645-4715-a3d8-8bdf70c3cffd'
    },

    {
        id: 4,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F4.jpeg?alt=media&token=addcfce8-aee4-48c3-a77e-da76d79e2554'
    },


    {
        id: 5,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F5.JPG?alt=media&token=ad4a2864-3926-4ba4-828c-e05544053798'
    },

    {
        id: 6,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F6.JPG?alt=media&token=13e000f5-9a96-4e20-92c8-f37166c74fae'
    },


    {
        id: 7,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2F7.JPG?alt=media&token=5ad9452b-d42f-4559-b668-e38c087f8699'
    },

    {
        id: 8,
        imgsrc: 'https://firebasestorage.googleapis.com/v0/b/moji-tees.appspot.com/o/Personal%2Fmau.jpeg?alt=media&token=517ece63-e7d0-4c94-b462-30a3d3de8db0&_gl=1*wkcgmk*_ga*NTA5OTQ1MjkxLjE2ODgzODcwOTQ.*_ga_CW55HF8NVT*MTY5NzA3MjQzMC42NS4xLjE2OTcwNzI0ODQuNi4wLjA.'
    },




]