import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import hexToRgba from 'hex-to-rgba';
import { state } from '../store'







export default function TextModal({ openTextModal, handleClosTextModal, SelectedColorHexCode, getInputText, getSelectedColor, getFontFamily, getFontSize }) {


    const [inputedText, setinputedText] = useState("#verytechy")
    const [selectedColor, setselectedColor] = useState('#FFFFFF')
    const [selectedFontFamily, setSelectedFontFamily] = useState("Impact");
    const [selectedFontSize, setSelectedFontSize] = useState("190px");





    const handleinputedTextchange = (event) => {
        setinputedText(event.target.value);
        getInputText(event.target.value)
    };



    const SelectedColor = (color) => {
        setselectedColor(color)
        getSelectedColor(color)

    }

    const handleFontFamilychange = (event) => {
        setSelectedFontFamily(event.target.value);
        getFontFamily(event.target.value)

    };


    const handleFontSizechange = (event) => {
        setSelectedFontSize(event.target.value);
        getFontSize(event.target.value)

    };




    const fontFamilies = [
        "Impact",
        "Arial",
        "Times New Roman",
        "Georgia",
        "Verdana",
        "Courier New",
        "Raleway",
        "Zapfino",

    ];


    const fontSizes = [
        "210",
        "150",
        "100",


    ];

    const handleSelectedColor = (color) => {
        setselectedColor(color);
        getSelectedColor(color);
    };




    return (

        <Modal
            open={openTextModal}
            onClose={handleClosTextModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                overflow: 'scroll'
            }}

        >

            <Box
                className='text-modal'
                style={{

                    borderRadius: '20px',
                    backgroundColor: 'grey'

                }}
            >


                <TextField
                    id="filled-basic"
                    label="Input text (Max: 10 Characters)"
                    variant="filled"
                    className='text-fields-input'
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'black' },
                    }}
                    value={state.decalText}
                    onChange={handleinputedTextchange}
                    inputProps={{
                        maxLength: 10,
                        style: {
                            fontFamily: selectedFontFamily,
                            // color: state.decalColor === "5C4337" ? `#${state.decalColor}` : state.decalColor,
                            color: `#${state.decalColor}`,

                            fontWeight: 'bold',
                            backgroundColor: 'grey',

                        }
                    }}
                />


                <span style={{ marginBottom: '0.5rem' }}>Text Color:</span>

                <div style={{
                    display: 'flex',


                }}>

                    <div
                        onClick={() => (SelectedColor('#FFFFFF'))}
                        style={{
                            height: '30px',
                            width: '30px',

                            backgroundColor: '#ccc',
                            marginRight: '0.5rem',

                        }}

                    ></div>

                    <div
                        onClick={() => (SelectedColor('#EE1A87'))}
                        style={{
                            height: '30px',
                            width: '30px',

                            backgroundColor: '#F866AF',
                            marginRight: '0.5rem',

                        }}

                    ></div>
                    <div
                        onClick={() => (SelectedColor('#652233'))}
                        style={{
                            height: '30px',
                            width: '30px',

                            backgroundColor: '#652233',
                            marginRight: '0.5rem',

                        }}

                    ></div>


                    <div
                        onClick={() => (SelectedColor('#2C4EDA'))}
                        style={{
                            height: '30px',
                            width: '30px',

                            backgroundColor: '#2C4EDA',
                            marginRight: '0.5rem',

                        }}

                    ></div>
                    {/* <div
                        onClick={() => (SelectedColor('#EFBD4E'))}
                        style={{
                            height: '20px', width: '20px',
                            backgroundColor: '#EFBD4E',

                            marginRight: '0.5rem'
                        }}

                    ></div> */}
                    {/* <div
                        onClick={() => (SelectedColor('#80C670'))}
                        style={{
                            height: '20px', width: '20px', backgroundColor: '#80C670',
                            marginRight: '0.5rem'
                        }}

                    ></div> */}
                    {/* <div
                        onClick={() => (SelectedColor('#726DE8'))}
                        style={{
                            height: '20px', width: '20px', backgroundColor: '#726DE8',
                            marginRight: '0.5rem'
                        }}

                    ></div> */}
                    {/* <div
                        onClick={() => (SelectedColor('#EF674E'))}
                        style={{
                            height: '20px', width: '20px', backgroundColor: '#EF674E',
                            marginBottom: '1rem',
                            marginRight: '0.5rem'
                        }}

                    ></div> */}

                    <div
                        onClick={() => (SelectedColor('#5C4337'))}
                        style={{
                            height: '30px', width: '30px', backgroundColor: '#5C4337',
                            marginBottom: '1rem',
                            marginRight: '0.5rem'
                        }}

                    ></div>
                    <div
                        onClick={() => (SelectedColor('#000000'))}
                        style={{ height: '30px', width: '30px', backgroundColor: '#353934', }}

                    ></div>


                </div>





                {/* <span style={{ marginBottom: '0.5rem' }}>Font Style:</span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: '1rem'
                    }}
                >
                    <div>
                        <select
                            value={selectedFontFamily}
                            onChange={handleFontFamilychange}
                        >
                            {fontFamilies.map((fontFamily) => (
                                <option key={fontFamily} value={fontFamily}>
                                    {fontFamily}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> */}
                {/* 
                <span style={{ marginBottom: '0.5rem' }}>Font Size:</span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: '2rem'
                    }}
                >
                    <div>
                        <select
                            value={selectedFontSize}
                            onChange={handleFontSizechange}
                        >
                            {fontSizes.map((fontsize) => (
                                <option key={fontsize} value={fontsize}>
                                    {fontsize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> */}



                <button onClick={handleClosTextModal}>OK</button>
            </Box>

        </Modal >

    )
}
