import { proxy } from 'valtio'
import icon1 from './Humaaans/icon1.png'
import nullImage from './Humaaans/mosaic5.jpg'




const state = proxy({
    intro: true,
    colors: ['#D84066', '#ECB71C', '#DE0228', '#FD5600',
        '#5EBA3E', '#353934', "#ccc", "#68A0D0",
        "#574891", '#E2D9BC', '#62605E', '#C68B8D', '#71ACA0', '#8B2638'],

    decals: ['react', 'three2', 'pmndrs'],
    color: "#8B2638",
    colorInEnglish: 'Mauve',
    decal: "👨🏽‍💻",
    decalText: encodeURIComponent('#aTechBro'),
    decalColor: 'FFFFFF',
    decalFontFamily: "Impact",
    decalFontSize: "null",
    decalEmojiImageSrc: "👩🏽‍💻",
    decalScale: 0.16,
    decalTextSize: 0.25,
    decalPositionX: 0,
    decalPositionY: -1,

    decalImage: nullImage,
    HumaansImageDecal: {
        newImage: icon1,
        id: 20
    },
    emojiDecalIsSelected: true,
    decalImageIsSelected: false,
    HumaansImageDecalIsSelected: false,
    paperSizeSelected: 'Large',
    ArtWorkPosition: 'Large Front',
    Price: '₵100',




    updateDacal: (newEmoji) => {

        state.decal = newEmoji;
    },

    updateText: (newText) => {
        state.decalText = newText;
    },

    updateDecalColor: (newColor) => {
        state.decalColor = newColor;
    },

    updateDecalFontFamily: (newfont) => {
        state.decalFontFamily = newfont

    },

    updateDecalFontSize: (newFontSize) => {
        state.decalFontSize = newFontSize
    },

    updateDecalEmojiSrc: (newimgsrc) => {
        state.decalEmojiImageSrc = newimgsrc
    },

    updateDecalScale: (newScale) => {
        state.decalScale = newScale
    },

    updateTextScale: (newScale) => {
        state.decalTextSize = newScale
    },

    updateDecalPositionX: (newScalex) => {
        state.decalPositionX = newScalex
    },

    updateDecalPositionY: (newScaley) => {
        state.decalPositionY = newScaley
    },

    updateImageDecal: (newImage) => {
        state.decalImage = newImage

    },

    upDateHumaansImageDecal: (newImage, id) => {
        state.HumaansImageDecal = {
            newImage: newImage,
            id: id
        };
    },

    updateEmojiDecalIsSelected: (toggler) => {
        state.emojiDecalIsSelected = toggler
    },


    updatedecalImageIsSelected: (toggler) => {
        state.decalImageIsSelected = toggler
    },


    updateHumaansImageDecalIsSelected: (toggler) => {
        state.HumaansImageDecalIsSelected = toggler
    },


    updatepaperSizeSelected: (size) => {
        state.paperSizeSelected = size
    },

    updateArtWorkPosition: (position) => {
        state.ArtWorkPosition = position
    },

    updateColorInEnglishName: (colorName) => {
        state.colorInEnglish = colorName
    },

    updatePrice: (newPrice) => {
        state.Price = newPrice
    }
})


export { state }






export const Emoji = (importedmojis) => {
    state.updateDacal(importedmojis)
}

export const DecalText = (importedText) => {
    state.updateText(importedText)

}

export const DecalColor = (importedColor) => {
    state.updateDecalColor(importedColor.replace("#", ""))

}

export const DecalTextFontFamily = (fontfamily) => {
    state.updateDecalFontFamily(fontfamily)


}


export const DecalTextFontSize = (fontSize) => {
    state.updateDecalFontSize(fontSize)
}

export const DecalEmojiSrc = (imgSrc) => {
    state.updateDecalEmojiSrc(imgSrc)


}


export const DecalScale = (scaleNumber) => {
    state.updateDecalScale(scaleNumber)

}

export const DecalSTextScale = (scaleNumber) => {
    state.updateTextScale(scaleNumber)

}


export const DecalPositionScale = (scaleNumberx, scaleNumbery) => {
    state.updateDecalPositionX(scaleNumberx)
    state.updateDecalPositionY(scaleNumbery)

}


export const ImageDecal = (newImage) => {
    state.updateImageDecal(newImage)
}

export const HumaansImageDecal = (newImage, id) => {
    state.upDateHumaansImageDecal(newImage, id)
}


export const emojiDecalIsSelected = (toggler) => {
    state.updateEmojiDecalIsSelected(toggler)
}

export const decalImageIsSelected = (toggler) => {
    state.updatedecalImageIsSelected(toggler)
}

export const HumaansImageDecalIsSelected = (toggler) => {
    state.updateHumaansImageDecalIsSelected(toggler)
}

export const PaperSizeSelected = (size) => {
    state.updatepaperSizeSelected(size)
}

export const ArtWorkPosition = (position) => {
    state.updateArtWorkPosition(position)
}

export const ColorInEnglish = (colorname) => {
    state.updateColorInEnglishName(colorname)
}

export const ShirtPrice = (newPrice) => {
    state.updatePrice(newPrice)
}