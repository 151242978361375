

import React, { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css'
import reportWebVitals from './reportWebVitals';
import { App as Canvas } from "./Canvas"
import { Overlay } from "./Overlay"
import { FidgetSpinner } from 'react-loader-spinner'
import Ecom from './Components/Ecom';
import { state } from '../src/store'

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  const [getIntroState, setIntrostate] = useState(false)



  const getIntroStateFunc = (state) => {
    setIntrostate(state)


  }






  return (
    <React.StrictMode>
      <div
        style={{ height: getIntroState === false ? '70vh' : '100vh', width: '100%', }}
        className={getIntroState === false ? 'main_of_all' : 'main_of_all_reversed'}

      >

        <Suspense fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              fontSize: '1rem',
              alignItems: 'center',
              height: '50vh',
              marginLeft: '5rem'


            }}>
            <p></p>
            <div>

              <FidgetSpinner
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                ballColors={['#726DE8', '#80C670', '#EFBD4E']}
                backgroundColor="#EF674E"


              />
              <p style={{ fontSize: '0.7rem', marginLeft: '1rem' }}>
                ...Weaving Shirt Magic <br /> Patience is Required.
              </p>
            </div>

          </div>
        }>

          <Canvas />
        </Suspense>
      </div>

      <Overlay getIntroStateFunc={getIntroStateFunc} />

      {state.intro && <Ecom getIntroStateFunc={getIntroStateFunc} />}


    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
