import Icon1 from './Humaaans/icon1.png'
import Icon2 from './Humaaans/icon2.png'
import Icon3 from './Humaaans/icon3.png'
import Icon4 from './Humaaans/icon4.png'
import Icon5 from './Humaaans/icon5.png'
import Icon6 from './Humaaans/icon6.png'
import Icon7 from './Humaaans/icon7.png'
import AILogo from './assets/AI image.png'



import reactlogo from '../src/Humaaans/JavaScript.png'
import JavaScriptLogo from '../src/Humaaans/react.png'
import python from '../src/Humaaans/python.png'
import html from '../src/Humaaans/html.png'
import java from '../src/Humaaans/java.png'
import vscode from '../src/Humaaans/vscode.png'
import android from '../src/Humaaans/android.png'
import dart from '../src/Humaaans/dart.png'


import lion1 from '../src/Humaaans/Lion1.png'
import lion2 from '../src/Humaaans/Lion2.png'
import lion3 from '../src/Humaaans/Lion3.png'
import flowers from '../src/Humaaans/flowers.png'
import afrimap from '../src/Humaaans/African.png'
import afriwoman2 from '../src/Humaaans/afriwoman2.png'
import afriwoman3 from '../src/Humaaans/afriwoman3.png'
import afriwoman4 from '../src/Humaaans/africwoman4.png'
import mosaic1 from '../src/Humaaans/mosaic.jpg'
import mosaic2 from '../src/Humaaans/mosaic.png'
import mosaic3 from '../src/Humaaans/mosaic2.jpg'
import mosaic4 from '../src/Humaaans/mosaic3.jpg'
import mosaic5 from '../src/Humaaans/mosaic5.jpg'
import mosaic6 from '../src/Humaaans/mosaic6.jpg'
import trees1 from '../src/Humaaans/tree1.png'
import trees2 from '../src/Humaaans/trees.png'
import trees3 from '../src/Humaaans/tree 2.png'
import compo1 from '../src/Humaaans/compo.jpg'
import compo2 from '../src/Humaaans/composition.jpg'

import woman from '../src/assets/freshmojis/woman.png'
import coffee from '../src/assets/freshmojis/coffee.png'
import dog from '../src/assets/freshmojis/dog.png'
import messi from '../src/assets/freshmojis/messi.png'
import ronaldo from '../src/assets/freshmojis/ronaldo.png'
import albert from '../src/assets/freshmojis/albert.png'
import eng from '../src/assets/freshmojis/eng.png'
import bumble from '../src/assets/freshmojis/bumble.png'

import airmax1 from '../src/assets/freshmojis/airone.png'
import airmax2 from '../src/assets/freshmojis/airtwo.png'
import airmax3 from '../src/assets/freshmojis/air3.png'


export const HumaansIconData = [

    {
        id: 1,
        imgsrc: AILogo

    },

    {
        id: 18,
        imgsrc: mosaic1

    },

    {
        id: 19,
        imgsrc: mosaic2

    },

    {
        id: 20,
        imgsrc: mosaic3

    },
    {
        id: 21,
        imgsrc: mosaic4

    },

    {
        id: 22,
        imgsrc: mosaic5

    },

    {
        id: 23,
        imgsrc: mosaic6

    },


    {
        id: 27,
        imgsrc: compo1

    },


    {
        id: 28,
        imgsrc: compo2

    },



    {
        id: 13,
        imgsrc: woman

    },

    {
        id: 26,
        imgsrc: dog

    },

    {
        id: 24,
        imgsrc: coffee

    },

    {
        id: 24.5,
        imgsrc: messi

    },

    {
        id: 25.5,
        imgsrc: ronaldo

    },

    {
        id: 25.6,
        imgsrc: albert

    },
    {
        id: 25.7,
        imgsrc: eng

    },

    {
        id: 25.8,
        imgsrc: bumble

    },

    {
        id: 25.8,
        imgsrc: airmax1

    },

    {
        id: 25.8,
        imgsrc: airmax2

    },

    {
        id: 25.9,
        imgsrc: airmax3

    },



    {
        id: 10,
        imgsrc: lion1

    },


    {
        id: 11,
        imgsrc: lion2

    },

    {
        id: 12,
        imgsrc: lion3

    },

    {
        id: 14,
        imgsrc: afrimap

    },

    {
        id: 15,
        imgsrc: afriwoman2

    },

    {
        id: 16,
        imgsrc: afriwoman3

    },

    {
        id: 17,
        imgsrc: afriwoman4

    },




    // {
    //     id: 25,
    //     imgsrc: trees2

    // },



    {
        id: 3,
        imgsrc: reactlogo

    },



    {
        id: 4,
        imgsrc: python

    },

    // {
    //     id: 5,
    //     imgsrc: html

    // },

    // {
    //     id: 6,
    //     imgsrc: java

    // },

    // {
    //     id: 7,
    //     imgsrc: vscode

    // },


    // {
    //     id: 8,
    //     imgsrc: android

    // },

    // {
    //     id: 9,
    //     imgsrc: dart

    // },

    // {
    //     id: 2,
    //     imgsrc: JavaScriptLogo

    // },





    // {
    //     id: 29,
    //     imgsrc: Icon1
    // },

    // {
    //     id: 30,
    //     imgsrc: Icon2
    // },

    // {
    //     id: 31,
    //     imgsrc: Icon3
    // },

    // {
    //     id: 32,
    //     imgsrc: Icon4
    // },

    // {
    //     id: 33,
    //     imgsrc: Icon5
    // },

    // {
    //     id: 34,
    //     imgsrc: Icon6
    // },

    // {
    //     id: 35,
    //     imgsrc: Icon7
    // },



]