
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
    apiKey: "AIzaSyD3L1rfYWfh8R1S9pIyrBzL9UghxT3wLYo",
    authDomain: "moji-tees.firebaseapp.com",
    projectId: "moji-tees",
    storageBucket: "moji-tees.appspot.com",
    messagingSenderId: "201605599",
    appId: "1:201605599:web:ee4684636f3f334d32c46a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app