import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { AiOutlineClose } from 'react-icons/ai';
import hexToRgba from 'hex-to-rgba';
import app from "../Firebase"
import { addDoc, getFirestore, collection, serverTimestamp, doc } from "firebase/firestore";
import { motion, progress } from 'framer-motion'
import { BsCheckCircle } from 'react-icons/bs';
import { uploadBytesResumable, ref, getDownloadURL, getStorage, uploadBytes, } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { useSnapshot } from 'valtio'
import { HumaansImageDecalIsSelected, decalImageIsSelected, emojiDecalIsSelected, state } from '../store'
import Ecom from './Ecom';







export default function OrderModal({ openModal, handleCloseModal, imgSrc, emojiName, Selectedcolor, SelectedColorHexCode, chosenEmoji, selectedTextColor, selectedText, selectedFontFamily, selectedFontSize, ecomPrice, nameOfShirt, buyingDirectly }) {




    const [fullname, setfullname] = useState("")
    const [email, setemail] = useState("")
    const [phoneNumber, setphoneNumber] = useState("")
    const [DeliveryLocation, setDeliveryLocation] = useState("")
    const [size, setSize] = useState("");
    const [Quantity, setQuantity] = useState("")
    const [phoneOperatingSystem, setphoneOperatingSystem] = useState("")
    const [isLoading, setIsloading] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [SentAtDate, setSentAtDate] = useState("")
    const [orderNumber, setOrderNumber] = useState("")
    const [ProgressNumber, setProgressNumber] = useState('0')
    const [StorageId, setStrogaeId] = useState("")

    const [chosenArtWork, setChosenArtWork] = useState("")


    const snap = useSnapshot(state);


    const handleFullNamehange = (event) => {
        setfullname(event.target.value);
    };

    const handleEmailChange = (event) => {
        setemail(event.target.value)
    }


    const handlePhoneNumberChange = (event) => {
        const phoneNumberWithoutSpaces = event.target.value.replace(/\s/g, '');
        setphoneNumber(phoneNumberWithoutSpaces);

    };

    const handleDeliveryLocationChange = (event) => {
        setDeliveryLocation(event.target.value)
    }

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value)
    }


    const handleOSChange = (event) => {
        setphoneOperatingSystem(event.target.value);
    };



    useEffect(() => {
        if (snap.emojiDecalIsSelected) {

            setChosenArtWork(chosenEmoji)

        } else if (snap.decalImageIsSelected) {
            setChosenArtWork(snap.decalImage)
        }

        else if (snap.HumaansImageDecalIsSelected) {
            setChosenArtWork(snap.HumaansImageDecal)
        }



    }, [snap.emojiDecalIsSelected, snap.decalImageIsSelected, snap.HumaansImageDecalIsSelected]);




    const sendToFirebase = async (event) => {
        const storage = getStorage(app);


        if (
            fullname.trim().length === 0 ||
            phoneNumber.trim().length === 0 ||
            DeliveryLocation.trim().length === 0 ||
            Quantity.trim().length === 0 ||
            size.trim().length === 0
        ) {
            alert("Please fill all the fields before you order");
        } else {
            setIsloading(true);
            event.preventDefault();
            const db = getFirestore(app);
            const response = await fetch(imgSrc);
            const responseofCustomImageUpload = await fetch(snap.decalImage);
            const ShirtImageWithArtWorkblob = await response.blob();
            const customImageArtWorkblob = await responseofCustomImageUpload.blob();
            const storageRef = ref(storage, `Uploded Story Images of ${phoneNumber}`);


            const blobArray = [ShirtImageWithArtWorkblob, customImageArtWorkblob];

            try {
                const promises = blobArray.map((blob) => {
                    const uniqueId = uuidv4();
                    const fileRef = ref(storage, `${storageRef}/${uniqueId}`);
                    const uploadTask = uploadBytesResumable(fileRef, blob);




                    return new Promise((resolve, reject) => {
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const progressnumber =
                                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                // Update the user interface to show the upload progress
                                setProgressNumber(progressnumber);

                            },
                            (error) => {
                                reject(error);
                            },
                            async () => {
                                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                                resolve(downloadUrl);
                            }
                        );
                    });
                });



                Promise.all(promises)
                    .then((downloadUrls) => {

                        addDoc(collection(db, "moji-tees-Orders"), {
                            FullName: fullname,
                            email: email,
                            phoneNumber: phoneNumber,
                            DeliveryLocation: DeliveryLocation,
                            Quantity: Quantity,
                            size: size,
                            ArtWorkPosition: snap.ArtWorkPosition,
                            emojiName: emojiName,
                            chosenArt: emojiName === 'Ecom' ? '' : chosenArtWork,
                            SelectedColor: emojiName === 'Ecom' ? '' : Selectedcolor,
                            SelectedTextColor: selectedTextColor,
                            selectedText: selectedText,
                            phoneOperatingSystem: phoneOperatingSystem,
                            SelectedColorHex: snap.color,
                            selectedFontFamily: selectedFontFamily,
                            selectedFontSize: selectedFontSize,
                            image: downloadUrls[0],
                            customImage: snap.decalImageIsSelected ? downloadUrls[1] : null,
                            SelectedEmoji: snap.emojiDecalIsSelected ? chosenEmoji : null,
                            GiftImageSelected: snap.HumaansImageDecalIsSelected ? 'Select Gift Image' : null,
                            NameOfShirt: emojiName === "Ecom" ? nameOfShirt : 'N/A',


                            // decalimage: downloadUrlforDecal,
                            contacted: false,
                            createdAt: serverTimestamp(),
                        })
                            .then(() => {
                                setTimeout(() => {
                                    setIsConfirmed(true);
                                    setSentAtDate(new Date());
                                }, 1000);
                                setIsloading(false);
                            })
                            .catch((error) => {
                                alert(`Error adding document to Firestore: ${error.message}`);
                                setIsloading(false);
                            });


                        setIsloading(false);



                        // Continue with the rest of your code, e.g., adding document to Firestore
                        // ...

                    })
                    .catch((error) => {
                        alert(`Error uploading image: ${error.message}`);
                        setIsloading(false);
                    });
            } catch (error) {
                alert(`Error`);
                setIsloading(false);
            }
        }
    };





    const okayClicked = () => {
        setIsConfirmed(false)
        setfullname("")
        setemail("")
        setphoneNumber("")
        setDeliveryLocation("")
        setQuantity("")
        setSize("")
        setphoneOperatingSystem("")
        handleCloseModal()
    }



    const WhatSapNumber = '+233508356802';
    const message = 'Hello!';

    const handleOpenWhatsapp = () => {
        // Construct the WhatsApp URL
        const whatsappUrl = `https://wa.me/${WhatSapNumber}?text=${encodeURIComponent(message)}`;

        // Open the URL in a new tab
        window.open(whatsappUrl, '_blank');
    };





    return (

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                overflow: 'scroll'
            }}

        >




            <Box
                className='modal-box'
                style={{
                    // backgroundColor: '#EDEADE',
                    borderRadius: '20px',
                    border: `3px solid ${snap.color}`,
                    // backgroundColor: hexToRgba(`${SelectedColorHexCode}`, 0.4),

                    backgroundColor: hexToRgba(`${snap.color}`, 0.4)
                }}
            >

                {isLoading ?
                    <div className='isloadingDiv' style={{ backgroundColor: hexToRgba(`${snap.color}`, 9) }}>
                        <div>
                            <img
                                style={{ width: '1.5rem', marginTop: '1rem', }}
                                src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" />
                        </div>

                        <p style={{
                            textAlign: 'center',
                            fontSize: '1.2rem', lineHeight: 1.2, color: 'black'
                        }}>
                            ... Placing your order
                        </p>

                    </div> : null}

                {isConfirmed ?
                    <div className='isConfirmedDiv'
                        style={{ overflow: 'scroll' }}>
                        <button onClick={okayClicked}
                            style={{ position: 'absolute', top: 10, left: 10 }}
                        >X</button>

                        <motion.div
                            animate={{ scale: 1.5 }}
                            transition={{
                                duration: 1,
                                repeat: Infinity,
                                repeatType: 'reverse'
                            }}




                        >
                            <BsCheckCircle size={30} />


                        </motion.div>


                        <p style={{
                            textAlign: 'center',
                            fontSize: '1.2rem', lineHeight: 1.2, color: 'black'
                        }}>
                            Order Successful!! <br />

                            <motion.div
                                animate={{ scale: 1.1 }}
                                transition={{
                                    duration: 1,
                                    repeat: Infinity,
                                    repeatType: 'reverse'
                                }}
                                style={{ textDecoration: 'underline', fontSize: '1rem' }}>
                                <p>PLEASE TAKE <br /> A SCREENSHOT OF<br /> YOUR ORDER DETAILS</p>


                            </motion.div>



                            <div className='table-div'>
                                <table>
                                    <tr>
                                        <td>Name</td>
                                        <td>{fullname}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone Number</td>
                                        <td>{phoneNumber}</td>

                                    </tr>
                                    <tr>
                                        <td>Delivery Location</td>
                                        <td>{DeliveryLocation}</td>

                                    </tr>
                                    {/* <tr>
                                        <td>Email</td>
                                        <td>{email}</td>

                                    </tr> */}
                                    <tr>
                                        <td>
                                            {
                                                snap.HumaansImageDecalIsSelected && 'Selected Gift Image'
                                            }

                                            {
                                                snap.decalImageIsSelected && 'Selected Custom Image'
                                            }

                                            {

                                                snap.emojiDecalIsSelected && 'Selected Emoji'
                                            }




                                        </td>
                                        <td style={{ position: 'relative' }}>


                                            {
                                                snap.HumaansImageDecalIsSelected &&
                                                <img
                                                    src={snap.HumaansImageDecal.newImage}
                                                    style={{ width: '3rem', height: '3rem' }}
                                                />
                                            }

                                            {
                                                snap.emojiDecalIsSelected &&

                                                <span className='selectedEmojiOnOrder'
                                                    style={{

                                                    }}> {buyingDirectly === true ? 'N/A' : snap.decal}

                                                </span>
                                            }

                                            {
                                                snap.decalImageIsSelected &&
                                                <img
                                                    src={snap.decalImage}
                                                    style={{ width: '3rem', height: '3rem' }}
                                                />
                                            }

                                        </td>


                                    </tr>
                                    <tr>
                                        <td> Shirt Color</td>
                                        <td style={{ position: 'relative' }}>
                                            {/* {Selectedcolor} */}

                                            <span className='selectedColorOnOrder'
                                                style={{
                                                    backgroundColor: snap.color,
                                                    border: '1px solid black'
                                                }}></span>
                                        </td>
                                    </tr>

                                    {/* <tr>
                                        <td>Inputed Text</td>
                                        <td style={{ position: 'relative' }}>
                                            {buyingDirectly === true ? 'N/A' : decodeURIComponent(snap.decalText)}
                                            <span className='selectedColorOnOrder'
                                                style={{
                                                    color: selectedTextColor,
                                                }}></span>
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Inputed Text Color</td>
                                        <td style={{ position: 'relative' }}>
                                            {buyingDirectly === true ? 'N/A' : snap.decalColor}



                                            <span className='selectedColorOnOrder'
                                                style={{
                                                    backgroundColor: buyingDirectly === true ? '' : selectedTextColor,
                                                    border: '1px solid black'
                                                }}></span>
                                        </td>
                                    </tr> */}
                                    {/* 
                                    <tr>
                                        <td>Font Family</td>
                                        <td style={{ position: 'relative' }}>{selectedFontFamily}

                                            <span className='selectedColorOnOrder'
                                                style={{
                                                    color: selectedTextColor,
                                                }}></span>
                                        </td>
                                    </tr> */}

                                    <tr>
                                        <td>Design Print Size</td>
                                        <td style={{ position: 'relative' }}>{emojiName === "Ecom" ? 'N/A' : snap.paperSizeSelected}

                                            {/* <span className='selectedColorOnOrder'
                                                style={{
                                                    color: selectedTextColor,
                                                }}></span> */}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Size</td>
                                        <td>{size}</td>

                                    </tr>
                                    <tr>
                                        <td>Quanitity</td>
                                        <td>{Quantity}</td>

                                    </tr>
                                    {/* <tr>
                                        <td>Name of Shirt</td>
                                        <td>{emojiName === "Ecom" ? nameOfShirt : "N/A"}</td>

                                    </tr> */}
                                    <tr>
                                        <td>Ordered on:</td>
                                        <td>{new Date().toString().replace("(Greenwich Mean Time)", "")}</td>

                                    </tr>



                                </table>
                            </div>


                            <br />



                            <div style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                We will get in touch with you shortly. <br />
                                <button
                                    onClick={okayClicked}
                                >Okay</button>
                            </div>

                        </p>

                    </div> : null}


                <div style={{ position: 'relative', }}>
                    <div className='image-div' style={{ backgroundColor: snap.color }}

                    >
                        <img
                            src={imgSrc}
                            className={emojiName === "Ecom" ? 'modal-created-image-ecom' : 'modal-created-image'}
                            draggable={false}
                            style={{ backgroundColor: snap.color }}

                        />
                    </div>

                    {emojiName == "Ecom" ?
                        <p className='price' style={{ backgroundColor: 'black', fontSize: '1rem' }}>
                            GH{ecomPrice}<br />
                            {/* <span style={{ fontSize: '0.7rem' }}> Excludes delivery charges.</span> */}
                        </p> :

                        <p className='price' style={{ backgroundColor: 'black', fontSize: '2rem' }}>
                            GH{snap.ArtWorkPosition === 'Large Front' ? snap.Price : "80"} <br />
                            {/* <span style={{ fontSize: '0.7rem' }}> Excludes delivery charges.</span> */}
                        </p>



                    }


                    <AiOutlineClose
                        className='cancel-modal'
                        color='#36454F'
                        onClick={handleCloseModal} />
                </div>


                <div className='modal-topography'>



                    <TextField
                        className='text-fields'
                        id="filled-basic"
                        label="Full Name"
                        variant="filled"
                        value={fullname}
                        onChange={handleFullNamehange}


                    />

                    {/* <TextField
                        id="filled-basic"
                        label="Valid Email Address"
                        variant="filled"
                        className='text-fields'
                        type={'email'}
                        value={email}
                        onChange={handleEmailChange}

                    /> */}


                    <TextField

                        id="filled-basic"
                        label="Phone Number"
                        variant="filled"
                        className='text-fields'
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        inputProps={{
                            inputMode: 'numeric',
                            // itemType: 'tel'

                        }}

                    />
                    <TextField
                        id="filled-basic"
                        label="Delivery Location"
                        variant="filled"
                        className='text-fields'
                        value={DeliveryLocation}
                        onChange={handleDeliveryLocationChange}

                    />


                    <br />

                    <div className='size-quantity-div'>
                        <TextField
                            id="filled-number"
                            label="Quantity"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            inputProps={{ min: 1 }}
                            value={Quantity}
                            onChange={handleQuantityChange}
                            style={{
                                width: '30%',
                                marginRight: '0.5rem',
                                backgroundColor: 'white',
                                borderRadius: '10px'
                            }}

                        />

                        <FormControl variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                            style={{ backgroundColor: 'white', borderRadius: '5px', }}>
                            <InputLabel id="demo-simple-select-standard-label">Size:</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={size}
                                onChange={handleSizeChange}
                                label="Age"
                            >
                                <MenuItem value={'Small'} className='menu-items'>Small</MenuItem>
                                <MenuItem value={'Medium'} className='menu-items'>Medium</MenuItem>
                                <MenuItem value={'Large'} className='menu-items'>Large</MenuItem>
                                <MenuItem value={'Xlarge'} className='menu-items'>XLarge</MenuItem>
                                <MenuItem value={'XXlarge'} className='menu-items'>XXLarge</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <FormControl variant="standard"
                            sx={{ m: 1, minWidth: 120 }}
                            style={{ backgroundColor: 'white', borderRadius: '5px', }}>
                            <InputLabel id="demo-simple-select-standard-label">Operating System:</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={phoneOperatingSystem}
                                onChange={handleOSChange}
                                label="OS"
                            >
                                <MenuItem value={'IOS'} className='menu-items'>IOS</MenuItem>
                                <MenuItem value={'Android'} className='menu-items'>Android</MenuItem>

                            </Select>

                        </FormControl>
                    </div> */}

                    <br />
                    <div className='order-div'
                        style={{ backgroundColor: snap.color, border: '1px solid black' }}
                        onClick={sendToFirebase}

                    >
                        <Button
                            className='order-btn'


                        >Order  </Button>


                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',

                    }}>
                        <p
                            style={{
                                backgroundColor: 'black',
                                padding: '0.7rem',
                                color: 'white',
                                textAlign: 'center'

                            }}
                        >Or Whatsapp <span
                            onClick={handleOpenWhatsapp}
                            style={{
                                backgroundColor: '#8FD8F6',
                                padding: '0.3rem',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                color: 'black',


                            }}

                        >+233508356802</span>  to place your order</p>
                    </div>


                </div>





            </Box>

        </Modal >

    )
}
