import { motion, AnimatePresence } from 'framer-motion'
import { useSnapshot } from 'valtio'
import {
    state,
    Emoji,
    DecalText,
    DecalColor,
    DecalTextFontFamily,
    DecalTextFontSize,
    DecalEmojiSrc,
    ImageDecal,
    emojiDecalIsSelected,
    decalImageIsSelected,
    HumaansImageDecalIsSelected,
    PaperSizeSelected,
    ArtWorkPosition,
    ColorInEnglish


} from './store'
import { useState, useRef, useEffect } from 'react'
import data from '@emoji-mart/data/sets/14/apple.json'
import Picker from '@emoji-mart/react'
import emojiImage from './assets/mojigif.gif'
import cart from './assets/cart.gif'
import textimage from "./assets/textgif.gif"
import OrderModal from './Components/OrderModal'
import TextModal from './Components/TextModal'
import humaansImage from './assets/humaansgif.gif'
import ImagesModal from './Components/ImagesModal'
import imageIcon from './imagegif.gif'
import Logo from './assets/logo.png'
import camera from './assets/cam.gif'
import './styles.css'
import InspirationModal from './Components/InspirationModal'
import arrow from './assets/arrow.png'
import { Oval } from 'react-loader-spinner'
import whatsapp from './assets/AI image.png'
import AILogo from './assets/AI.png'
import Swal from 'sweetalert2'
import eye from './assets/eye.gif'
import VirtualModal from './Components/VirtualModal'








export function Overlay({ getIntroStateFunc }) {

    const snap = useSnapshot(state)

    const transition = { type: 'spring', duration: 0.8 }
    const config = {
        initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
        animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
        exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
    }

    const userAgent = navigator.userAgent;
    const isFirefox = /firefox/i.test(userAgent);







    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: snap.intro ? '70vh' : '95vh',
            overflowY: 'hidden'



        }}>
            <AnimatePresence>
                {snap.intro ? (
                    <motion.section key="main" {...config} className='section-main'>
                        <div className="section--container">
                            <motion.div
                                key="title"
                                initial={{ x: 100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ type: 'spring', damping: 5, stiffness: 40, restDelta: 0.001, duration: 0.3 }}>
                                <h1 style={{ overflowY: 'hidden' }}>
                                    <span style={{ color: 'black' }} >M</span>

                                    <span className='emoji-in-heading'>
                                        {/* <img src={emojiMain}
                                            className='emojimain'
                                            alt="" /> */}
                                        😜
                                    </span>
                                    <span style={{ color: 'black' }} >J</span>
                                    <span style={{ color: 'black' }} >I</span>
                                </h1>
                                <p className='Tees'>
                                    <span style={{ color: '#EFBD4E' }} >T</span>
                                    <span style={{ color: '#80C670' }} >E</span>
                                    <span style={{ color: '#726DE8' }} >E'</span>
                                    <span style={{ color: '#EF674E' }} >S</span>
                                </p>


                            </motion.div>
                            <div className="support--content">
                                <motion.div
                                    key="p"
                                    initial={{ y: 100, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{
                                        type: 'spring',
                                        damping: 7,
                                        stiffness: 30,
                                        restDelta: 0.001,
                                        duration: 0.6,
                                        delay: 0.2,
                                        delayChildren: 0.2

                                    }}>
                                    <p className='small-texts' style={{ color: 'black' }}>
                                        "Express your mood..." <br />

                                    </p>


                                    <motion.div
                                        animate={{ scale: 1.1 }}
                                        transition={{
                                            duration: 1,
                                            repeat: Infinity,
                                            repeatType: 'reverse'
                                        }}
                                        style={{ marginLeft: '2rem' }}

                                    >
                                        {/* <button
                                            style={{ background: snap.color, }}
                                            className='start-creating'
                                            onClick={() => (state.intro = false)}
                                        >
                                            CREATE
                                        </button> */}

                                    </motion.div>
                                </motion.div>
                            </div>

                            <p className='call-us'>
                                {/* 
                                <img src={whatsapp} style={{ width: '10rem' }} alt="" /> */}

                                {/* <a href="mailto:randydavoh@yahoo.com">email: randydavoh@yahoo.com</a> <br /> */}
                                {/* <a>+233508356802 </a> <br /> */}
                                {/* <a href="https://lordicon.com/" style={{ fontSize: '0.5rem' }}>Icons by Lordicon.com</a> */}

                            </p>

                            {/* <p className='call-us'>
                                {isFirefox && (
                                    <p>Please open on Chrome, Safari or Edge to use the text customization feature.</p>
                                )}
                                <a href="mailto:randydavoh@yahoo.com">email: randydavoh@yahoo.com</a> <br />
                                <a href="https://lordicon.com/" style={{ fontSize: '0.5rem' }}>Icons by Lordicon.com</a>
                            </p> */}
                        </div>
                    </motion.section>
                ) : (
                    <motion.section key="custom" {...config}>
                        <Inspiration getIntroStateFunc={getIntroStateFunc} />
                        {/* <VirtualTryOn /> */}
                        <DecalPosition />

                        <Customizer />

                    </motion.section>
                )}
            </AnimatePresence>
        </div>
    )
}


function Customizer({ }) {

    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [chosenEmoji, setChosenEmoji] = useState('👱🏾‍♀️')
    const [imgSrc, setImageSrc] = useState("")
    const [emojiName, setEmojiName] = useState('Woman: Blond Hair')


    const [selectedImage, setSelectedImage] = useState(null);

    const [Selectedcolor, setSelectedColor] = useState("Mauve")
    const [SelectedColorHexCode, setSelectedColorHexCode] = useState("#574891")
    const [selectedTextColor, setSelectedTextColor] = useState("white")
    const [selectedText, setSelectedText] = useState("#blondie")
    const [selectedFontFamily, setSelectedFontFamily] = useState("Impact")
    const [selectedFontSize, setSeletedFontSize] = useState("190")

    const [emojiImageLoaded, setEmojiImageLoaded] = useState(true)
    const [GiftImageLoaded, setGiftImageLoaded] = useState(true)
    const [TextImageLoaded, setTextImageLoaded] = useState(true)
    const [ShopImageLoaded, setShopImageLoaded] = useState(true)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [openTextModal, setOpenTextModal] = useState(false);
    const handleOpenTextModal = () => setOpenTextModal(true);
    const handleClosTextModal = () => setOpenTextModal(false);

    const [openImagesModal, setOpenImagesModal] = useState(false)
    const handleOpenImagesModal = () => {
        setOpenImagesModal(true)
        setShowEmojiPicker(false)
    };
    const handleCloseImagesModal = () => setOpenImagesModal(false);

    const onSelectedEmoji = async (emoji) => {

        Emoji(emoji.native)
        setChosenEmoji(emoji.native)
        setEmojiName(emoji.name)
        setShowEmojiPicker(false)
        // DecalEmojiSrc(`https://emojicdn.elk.sh/${emoji.native}`)
        DecalEmojiSrc(emoji.native)
        emojiDecalIsSelected(true)
        decalImageIsSelected(false)
        HumaansImageDecalIsSelected(false)





        // const src = `https://emojicdn.elk.sh/${emoji.native}`;

        // // Fetch the image data
        // const response = await fetch(src);
        // const blob = await response.blob();

        // // Create a temporary download link
        // const downloadLink = document.createElement('a');
        // downloadLink.href = URL.createObjectURL(blob);
        // downloadLink.download = `imageos01sooowwe.png`;

        // // Set the desired image size
        // const width = 800;
        // const height = 800;

        // // Create an HTML canvas element
        // const canvas = document.createElement('canvas');
        // canvas.width = width;
        // canvas.height = height;

        // const ctx = canvas.getContext('2d');

        // // Draw the image on the canvas with the desired size
        // const image = new Image();
        // image.src = URL.createObjectURL(blob);
        // image.onload = () => {
        //     ctx.drawImage(image, 0, 0, width, height);

        //     // Convert the canvas content to a data URL
        //     const dataUrl = canvas.toDataURL('image/png');

        //     // Set the modified data URL as the download link's href
        //     downloadLink.href = dataUrl;

        //     // Simulate a click on the download link
        //     downloadLink.click();

        //     // Clean up the temporary download link
        //     URL.revokeObjectURL(downloadLink.href);
        // };





    }

    const showEmojiPickerFunction = () => {
        setShowEmojiPicker(!showEmojiPicker)
        setOpenImagesModal(false)


    }

    const handleShop = () => {
        const link = document.createElement('a')
        link.setAttribute('upload', 'canvas.png')
        link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png'))
        setImageSrc(link.href)
        handleOpenModal()
    }


    const getInputText = (inputedText) => {
        const encodedInput = encodeURIComponent(inputedText)
        DecalText(encodedInput)
        setSelectedText(encodedInput)


    }

    const getSelectedColor = (selectedColor) => {
        DecalColor(selectedColor)
        setSelectedTextColor(selectedColor)


    }

    const getFontFamily = (selectedFontFamily) => {
        DecalTextFontFamily(selectedFontFamily)
        setSelectedFontFamily(selectedFontFamily)

    }

    const getFontSize = (selectedfontSize) => {
        DecalTextFontSize(selectedfontSize)
        setSeletedFontSize(selectedFontSize)

    }


    // const colorSelectFunction = (color) => {
    //     if (color === "#ccc") {
    //         setSelectedColor("Light Grey")
    //     }
    //     else if (color === "#EFBD4E") {
    //         setSelectedColor("Golden Rod")
    //     }
    //     else if (color === "#80C670") {
    //         setSelectedColor("Yellow Green")
    //     }
    //     else if (color === "#726DE8") {
    //         setSelectedColor("Slate Blue")
    //     }

    //     else if (color === "#EF674E") {
    //         setSelectedColor("Coral")
    //     }
    //     else if (color === "#353934") {
    //         setSelectedColor("Dark Slate Gray")
    //     }
    // }

    // const colorSelectFunction = (color) => {
    //     if (color === "#F866AF") {
    //         setSelectedColor("Hot Pink")
    //     }
    //     else if (color === '#474748') {
    //         setSelectedColor("Dark Grey")
    //     }
    //     else if (color === '#5C4337') {
    //         setSelectedColor("Coffee Brown")
    //     }
    //     else if (color === '#EFBD4E') {
    //         setSelectedColor("Yellow")
    //     }

    //     else if (color === "#6E7359") {
    //         setSelectedColor("Navy Green")
    //     }
    //     else if (color === "#652233") {
    //         setSelectedColor("Wine")
    //     }
    // }


    const colorSelectFunction = (color) => {
        if (color === "#D84066") {
            setSelectedColor("Hot Pink")
            setSelectedColorHexCode(color)
            ColorInEnglish("Hot Pink")

        }
        else if (color === '#ECB71C') {
            setSelectedColor("Yellow")
            setSelectedColorHexCode(color)
            ColorInEnglish("Yellow")
        }
        else if (color === '#DE0228') {

            setSelectedColor("Red")
            setSelectedColorHexCode(color)
            ColorInEnglish("Red")
        }

        else if (color === '#FD5600') {
            setSelectedColor("Orange")
            setSelectedColorHexCode(color)
            ColorInEnglish("Orange")
        }


        else if (color === "#5EBA3E") {
            setSelectedColor("Lemon Green")
            setSelectedColorHexCode(color)
            ColorInEnglish("Lemon Green")
        }

        else if (color === "#353934") {
            setSelectedColor("Black")
            setSelectedColorHexCode(color)
            ColorInEnglish("Black")
        }

        else if (color === "#ccc") {
            setSelectedColor("White")
            setSelectedColorHexCode(color)
            ColorInEnglish("White")
        }

        else if (color === "#68A0D0") {
            setSelectedColor("Sea Blue")
            setSelectedColorHexCode(color)
            ColorInEnglish("Sea Blue")
        }

        else if (color === "#574891") {
            setSelectedColor("Mauve")
            setSelectedColorHexCode(color)
            ColorInEnglish("Mauve")
        }

        else if (color === "#E2D9BC") {
            setSelectedColor("Cream")
            setSelectedColorHexCode(color)
            ColorInEnglish("Cream")
        }

        else if (color === "#62605E") {
            setSelectedColor("Ash")
            setSelectedColorHexCode(color)
            ColorInEnglish("Ash")
        }

        else if (color === "#C68B8D") {
            setSelectedColor("Light Pink")
            setSelectedColorHexCode(color)
            ColorInEnglish("Light Pink")
        }

        else if (color === "#71ACA0") {
            setSelectedColor("Light Green")
            setSelectedColorHexCode(color)
            ColorInEnglish("Light Green")
        }
        else if (color === '#8B2638') {
            setSelectedColor("Wine")
            setSelectedColorHexCode(color)
            ColorInEnglish("Wine")
        }









    }


    const fileInputRef = useRef(null);
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        ImageDecal(imageUrl)
        emojiDecalIsSelected(false)
        decalImageIsSelected(true)
        HumaansImageDecalIsSelected(false)
    };

    const handleImageClick = () => {
        fileInputRef.current.click();

    };



    // const userAgent = navigator.userAgent

    // const isFirefox = /firefox/i.test(userAgent)


    const snap = useSnapshot(state)





    const handleEmojiImageLoad = () => {
        setEmojiImageLoaded(false)
    };



    const handleGiftImageLoad = () => {
        setGiftImageLoaded(false)
    };

    const handleTextImageLoad = () => {
        setTextImageLoaded(false)
    };


    const handleShopImage = () => {
        setShopImageLoaded(false)
    };


    return (
        <>
            <div style={{

                display: 'flex',
                justifyContent: 'center'
            }}>
                <span
                    style={{
                        position: 'absolute',
                        fontWeight: 'bold',
                        top: '17%',
                        color: snap.color,
                        backgroundColor: 'black',
                        padding: '0.3rem',
                        borderRadius: 10
                    }}>
                    {snap.colorInEnglish}: GH{snap.ArtWorkPosition === 'Large Front' ? snap.Price : "₵80"}
                </span>
            </div>
            <div className="customizer">
                <div className="color-options">
                    {snap.colors.map((color) => (
                        <div key={color} className={`circle`}
                            style={{ background: color }}
                            onClick={() => {
                                state.color = color
                                colorSelectFunction(state.color)
                                setSelectedColorHexCode(state.color)

                            }}>

                        </div>
                    ))}
                </div>
                <div className="decals">
                    <div className='emoji-picker-div'

                    >
                        {
                            showEmojiPicker ?
                                <Picker
                                    data={data}
                                    onEmojiSelect={onSelectedEmoji}
                                    className='emoji-picker'
                                    emojiButtonColors={['rgba(102, 51, 153, .2)']}
                                    icons={'solid'}
                                    theme='light'
                                    skinTonePosition={'preview'}
                                    set={'apple'}

                                /> : null
                        }

                    </div>




                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                        <span>

                        </span>

                        {/* <img
                            src={emojiImage}
                            className='adddecalsbtn'
                            onClick={showEmojiPickerFunction}
                            onLoad={handleEmojiImageLoad}

                        /> */}

                        {/* {
                            emojiImageLoaded &&

                            <div className='spinner'>
                                <div>
                                    <Oval
                                        color="#D84066"
                                        height={40}
                                        width={40}
                                    />
                                </div>

                            </div>


                        } */}




                        {/* Images Upload */}
                        <div>
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}

                            />

                            <img
                                src={imageIcon}
                                alt="Placeholder"
                                className='adddecalsbtn'
                                onClick={handleImageClick}
                            />

                        </div>



                        <img
                            src={humaansImage}
                            className='adddecalsbtn'
                            onClick={handleOpenImagesModal}
                            onLoad={handleGiftImageLoad}

                        />

                        {
                            GiftImageLoaded &&
                            <div className='spinner'>
                                <div>
                                    <Oval
                                        color="#F4442E"
                                        height={40}
                                        width={40}

                                    />
                                </div>

                            </div>
                        }

                        {/* <img
                            src={textimage}
                            className='adddecalsbtn'
                            onClick={handleOpenTextModal}
                            onLoad={handleTextImageLoad}
                        /> */}


                        {/* {
                            TextImageLoaded &&
                            <div className='spinner'>
                                <div>
                                    <Oval
                                        color="#ECB71C"
                                        height={40}
                                        width={40}
                                    />
                                </div>

                            </div>

                        } */}
                    </div>
                </div>


                <div
                    className="shop"
                    onClick={handleShop}

                >

                    <img src={cart}
                        style={{
                            width: '5rem',
                            backgroundColor: snap.color,
                            borderRadius: '50px'
                        }}

                        onLoad={handleShopImage}

                        className='shopping-cart'
                    />

                    {
                        ShopImageLoaded &&
                        <div className='spinner' style={{}}>
                            <div>
                                <Oval
                                    color="#5EBA3E"
                                    height={40}
                                    width={40}

                                />
                            </div>

                        </div>



                    }

                </div>


                <OrderModal
                    openModal={openModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    imgSrc={imgSrc}
                    emojiName={emojiName}
                    chosenEmoji={chosenEmoji}
                    Selectedcolor={Selectedcolor}
                    SelectedColorHexCode={SelectedColorHexCode}
                    selectedTextColor={selectedTextColor}
                    selectedText={selectedText}
                    selectedFontFamily={selectedFontFamily}
                    selectedFontSize={selectedFontSize}

                />

                <TextModal
                    openTextModal={openTextModal}
                    handleClosTextModal={handleClosTextModal}
                    getInputText={getInputText}
                    getSelectedColor={getSelectedColor}
                    getFontFamily={getFontFamily}
                    getFontSize={getFontSize}
                    SelectedColorHexCode={SelectedColorHexCode}


                />

                <ImagesModal
                    openImagesModal={openImagesModal}
                    handleCloseImagesModal={handleCloseImagesModal}
                />




            </div>
        </>
    )
}


function AItext({ }) {



    const handleGetinTouch = () => {
        Swal.fire({
            title: 'AI Avatars',
            text: 'For Your AI-Generated Avatars Please WhatsApp +233508356802',
            imageUrl: whatsapp,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',

        })

    }

    return (
        <div className='AItext'
            style={{

                // position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                flexDirection: 'column'


            }}
        >


            <img
                src={AILogo}
                className='adddecalsbtn'
                onClick={handleGetinTouch}
                style={{

                }}


            />




        </div>
    )
}





function Inspiration({ }) {

    const [openInspirationModal, setOpenInspirationModal] = useState(false);
    const [displayGet, setDisplayGet] = useState(false)
    const [cameraImageLoaded, setCameraImageLoaded] = useState(true)



    const handleOpenInspirationModal = () => setOpenInspirationModal(!openInspirationModal);
    const handleCloseInspirationModal = () => setOpenInspirationModal(false);

    const handleCameraImageLoad = () => {
        setCameraImageLoaded(false)
    };


    return (
        <div className='AItext'
            style={{
                position: 'absolute',
                top: '0%',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0.3rem',






            }}
        >


            <div onClick={() => (window.location.reload())}>

                <h2 style={{ padding: '0rem 0.5rem' }}>{'X'}</h2>
            </div>

            <div>
                <img
                    src={Logo}
                    alt=""
                    className='logo'
                    style={{ width: '3rem', margin: '1rem' }}
                />

            </div>



            <div onClick={handleOpenInspirationModal}
            // style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}

            >
                <img
                    src={camera}
                    alt=""
                    className='logo'
                    // style={{ position: 'absolute' }}
                    onLoad={handleCameraImageLoad}
                />
                {
                    cameraImageLoaded &&
                    <div className='spinner'>
                        <div>
                            <Oval
                                color="#363934"
                                height={40}
                                width={40}
                            />
                        </div>

                    </div>


                }



            </div>



            <InspirationModal
                openInspirationModal={openInspirationModal}
                handleCloseInspirationModal={handleCloseInspirationModal}
            />


        </div >



    )
}





function DecalPosition() {

    const [artworkposition, setartworkposition] = useState("");


    const ArtWorkSizes = [
        "Large Front",
        "Small Front",
        "Left Chest",
        "Right Chest",

    ];


    const handleArtWorkPositionSizeChange = (event) => {
        setartworkposition(event.target.value);
        ArtWorkPosition(event.target.value)
        setartworkposition(state.ArtWorkPosition)

    };


    useEffect(() => {
        setartworkposition(state.ArtWorkPosition)
    }, [artworkposition]);



    return (
        <div

            style={{

                position: 'absolute',
                top: '10%',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'


            }}
        >

            <div
                style={{
                    display: 'flex',

                }}
            >
                <span style={{
                    marginBottom: '0.5rem',
                    fontSize: '1.2rem',
                    fontWeight: 'bold'

                }}>Artwork Position:</span>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: '2rem'
                    }}
                >
                    <div>
                        <select
                            value={artworkposition}
                            onChange={handleArtWorkPositionSizeChange}
                            style={{ fontSize: '1rem' }}
                        >
                            {ArtWorkSizes.map((artworkpositionValue) => (

                                <option key={artworkpositionValue} value={artworkpositionValue}>
                                    {artworkpositionValue}


                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>


        </div>
    )
}




function VirtualTryOn() {

    const [OpenVirtualModal, setOpenVirtualModal] = useState(false);
    const [VirtualImageLoaded, setVirtualImageLoaded] = useState(true)



    const handleOpenVirtualModalModal = () => setOpenVirtualModal(!OpenVirtualModal);
    const handleCloseVirtualModalModal = () => setOpenVirtualModal(false);

    const handleVirtualImageLoad = () => {
        setVirtualImageLoaded(false)
    };


    return (
        <div className='AItext'

        >




            <div onClick={handleOpenVirtualModalModal}
                style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'flex-end',
                    // position: 'absolute',


                }}



            >


                <img
                    src={eye}
                    alt=""
                    className='logo'
                    style={{ position: 'absolute', top: '10%', zIndex: 99 }}
                    onLoad={handleVirtualImageLoad}
                />
                {
                    VirtualImageLoaded &&
                    <div className='spinner'>
                        <div>
                            <Oval
                                color="#363934"
                                height={40}
                                width={40}
                            />
                        </div>

                    </div>


                }

                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'

                    }}
                >




                </span>

            </div>



            <VirtualModal
                OpenVirtualModal={OpenVirtualModal}
                handleCloseVirtualModalModal={handleCloseVirtualModalModal}
            />


        </div>



    )
}
