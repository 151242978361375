import { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame, useLoader, } from '@react-three/fiber'
import {
  useGLTF, useTexture,
  AccumulativeShadows,
  RandomizedLight,
  Decal,
  Environment,
  Center,

} from '@react-three/drei'
import { easing } from 'maath'
import { useSnapshot } from 'valtio'
import { state } from './store'
import axios from 'axios'








export const App = ({ position = [0, 0, 2.5], fov = 25 }) => {



  return (

    <Canvas
      shadows
      camera={{ position, fov }}
      gl={{ preserveDrawingBuffer: true }}
      eventSource={document.getElementById("root")}
      eventPrefix="client"

    >
      <ambientLight intensity={0.5} />
      <Environment preset="city" />
      <CameraRig>
        {/* <Backdrop /> */}
        <Center >
          <Shirt />
        </Center>
      </CameraRig>


    </Canvas>


  );
};





function Backdrop() {
  const shadows = useRef()
  useFrame((state, delta) => easing.dampC(
    shadows.current.getMesh().material.color,
    state.color, 0.25, delta))
  return (
    <>
      <AccumulativeShadows
        ref={shadows}
        temporal
        frames={60}
        alphaTest={0.85}
        scale={10}
        rotation={[Math.PI / 2, 0, 0]}
        position={[0, 0, -0.14]}

      >
        <RandomizedLight amount={4} radius={9} intensity={0.55} ambient={0.25} position={[5, 5, -10]} />
        <RandomizedLight amount={4} radius={5} intensity={0.25} ambient={0.55} position={[-5, 5, -9]} />

      </AccumulativeShadows>

    </>

  )
}

function CameraRig({ children }) {
  const group = useRef()
  const snap = useSnapshot(state)




  useFrame((state, delta) => {


    easing.damp3(state.camera.position, [snap.intro ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
    easing.dampE(group.current.rotation, [-state.pointer.y / 10000, state.pointer.x / 10000, 0], 0.25, delta)
  })
  return <group ref={group}>{children}</group>

}



function Shirt(props) {

  const [artworkpositionstate, setartworkposition] = useState([0, 0, 0.16])
  const [artworkScale, setartworkscale] = useState(0.21)
  const [TextYLevel, setTextYLevel] = useState(-0.14)
  const [TextScale, setTextScale] = useState(0.20)
  const [TextPosition, setTextPosition] = useState([0.02, -0.14, 0.1])
  const [time, setTime] = useState(0);
  const [textColorOnShirt, setTextColorOnShirt] = useState('#FFFFFF')

  const snap = useSnapshot(state)


  const svg = `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='200'>
    <text y='110' x='130' font-size='50px' text-anchor='middle' fill='%23${snap.decalColor}'
      font-family='${snap.decalFontFamily}'>${snap.decalText}
      </text>
</svg>`;





  // const emojiImageTexture = useTexture(snap.decal)
  const emojiImageTexture = useTexture(snap.decalImage)
  const UploadedImageTexure = useTexture(snap.decalImage)
  const SelectedHumansDecalImage = useTexture(snap.HumaansImageDecal.newImage)



  const textTexture = useTexture(svg)


  const { nodes, materials } = useGLTF('/shirtmodel.glb')


  useFrame((state, delta) => easing.dampC(materials.lambert1.color, snap.color, 0.25, delta))




  useEffect(() => {
    if (snap.ArtWorkPosition === "Large Front") {
      setartworkposition([0, 0, 0.16])
      setartworkscale(0.21)
      setTextPosition([0.02, -0.14, 0.1])
      setTextScale(0.20)

    } else if (snap.ArtWorkPosition === "Small Front") {
      setartworkposition([0, 0, 0.16])
      setartworkscale(0.14)
      setTextPosition([0.01, -0.1, 0.1])
      setTextScale(0.15)

    }


    else if (snap.ArtWorkPosition === "Right Chest") {
      setartworkposition([-0.1, 0.05, 0.09])
      setartworkscale(0.10)
      setTextPosition([-0.09, -0.015, 0.1])
      setTextScale(0.10)

    } else if (snap.ArtWorkPosition === "Left Chest") {
      setartworkposition([0.1, 0.05, 0.09])
      setartworkscale(0.10)
      setTextPosition([0.10, -0.016, 0.1])
      setTextScale(0.10)

    }
  }, [snap.ArtWorkPosition]);



  return (
    <>
      <mesh
        scale={snap.intro ? [1.2, 1.2, 1] : [0.8, 0.8, 1]}
        castShadow
        geometry={nodes.T_Shirt_male.geometry}
        material={materials.lambert1}
        material-roughness={1}
        {...props}
        dispose={null}

      >


        {
          snap.emojiDecalIsSelected && (
            <Decal
              position={artworkpositionstate}
              rotation={[0, 0, 0]}
              scale={artworkScale}
              map={emojiImageTexture}
              transparent={true}
              depthTest={false}
              depthWrite={false}


            />
          )
        }

        {
          snap.decalImageIsSelected && (
            <Decal
              position={artworkpositionstate}
              rotation={[0, 0, 0]}
              scale={artworkScale}
              map={UploadedImageTexure}
              transparent={true}
              depthTest={false}
              depthWrite={false}

            />
          )
        }




        {
          snap.HumaansImageDecalIsSelected && (
            <Decal
              position={artworkpositionstate}
              rotation={[0, 0, 0]}
              scale={artworkScale}
              map={SelectedHumansDecalImage}
              transparent={true}
              depthTest={false}
              depthWrite={false}

            />
          )
        }



        {/* <Decal
          position={TextPosition}
          rotation={[0, 0, 0]}
          scale={TextScale}
          map={textTexture}
          transparent={true}
          depthTest={false}
          depthWrite={false}
        /> */}

      </mesh>
    </>

  )
}



useGLTF.preload('/shirt_baked_collapsed.glb')






