import React, { useState, useEffect } from 'react'
import { state, } from '../store'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
// import Carousel from 'react-material-ui-carousel'
import { EmojiData, AIData, TechData } from './EcomPath'
import { FidgetSpinner } from 'react-loader-spinner'
import Carousel from 'react-bootstrap/Carousel';



import { Button, CardActionArea, CardActions } from '@mui/material';
import '../Ecom.css'
import OrderModal from './OrderModal';
import artimg from '../color.png'
import cart3d from '../cart3d.png'
import { motion } from "framer-motion"





const variants = {
    slidein: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1
        }

    },

    fadeIn: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1
        }

    },



    fadeInFromBottom: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1
        }

    },

    fadeInFromTop: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1
        }

    },




}

export default function Ecom({ getIntroStateFunc }) {

    const [stateCheck, setStateCheck] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [imgsrc, setImgsrc] = useState("")
    const [price, setPrice] = useState('')
    const [EmojiimageLoaders, setEmojiImageLoaders] = useState(EmojiData.map(() => true));
    const [AImageLoaders, setAIImageLoaders] = useState(AIData.map(() => true));
    const [TechImageLoaders, setTechImageLoaders] = useState(TechData.map(() => true));
    const [nameOfShirt, setNameOfShirt] = useState('')
    const [buyingDirectly, setBuyingDirectly] = useState(false)


    const emojiCustomizedPressed = (
        color,
        artwork,
        text,
        artposition,
        colorNameInEnglish,
        textColor,
        price
    ) => {

        state.intro = false
        setStateCheck(true)
        getIntroStateFunc(true)
        state.decalImageIsSelected = false
        state.emojiDecalIsSelected = true
        state.color = color
        state.decal = artwork
        state.decalText = encodeURIComponent(text)
        state.ArtWorkPosition = artposition
        state.colorInEnglish = colorNameInEnglish
        state.decalColor = textColor
        state.Price = price

    }



    const AICustomizedPressed = (
        color,
        text,
        artposition,
        colorNameInEnglish,
        textColor,
        AIArtWork,
        price
    ) => {
        state.intro = false
        state.decalImageIsSelected = true
        state.emojiDecalIsSelected = false
        setStateCheck(true)
        getIntroStateFunc(true)
        state.color = color
        state.decalText = encodeURIComponent(text)
        state.ArtWorkPosition = artposition
        state.colorInEnglish = colorNameInEnglish
        state.decalColor = textColor
        state.decalImage = AIArtWork
        state.Price = price


    }

    const TechCustomizedPressed = (
        color,
        text,
        artposition,
        colorNameInEnglish,
        textColor,
        TechArtWork,
        price
    ) => {
        state.intro = false
        state.decalImageIsSelected = true
        state.emojiDecalIsSelected = false
        setStateCheck(true)
        getIntroStateFunc(true)
        state.color = color
        state.decalText = encodeURIComponent(text)
        state.ArtWorkPosition = artposition
        state.colorInEnglish = colorNameInEnglish
        state.decalColor = textColor
        state.decalImage = TechArtWork
        state.Price = price


    }




    const openModalFunction = (imgsrc, price, color, name) => {
        setOpenModal(true)
        setBuyingDirectly(true)
        setImgsrc(imgsrc)
        setPrice(price)
        state.color = color
        setNameOfShirt(name)
    }

    const handleCloseModal = () => setOpenModal(false);




    const handleEmojimageLoad = (index) => {
        setEmojiImageLoaders(prevLoaders => prevLoaders.map((loader, i) => (i === index ? false : loader)));
    };




    const handleAImageLoad = (index) => {
        setAIImageLoaders(prevLoaders => prevLoaders.map((loader, i) => (i === index ? false : loader)));
    };



    const handleTechImageLoad = (index) => {
        setTechImageLoaders(prevLoaders => prevLoaders.map((loader, i) => (i === index ? false : loader)));
    };


    const handleOnContextMenu = (e) => {
        e.preventDefault();
    };


    return (
        <div style={{ backgroundColor: '#DAD9E0', margin: 0, padding: 10 }}>



            {
                stateCheck === false &&

                <div>


                    {/* AI branded */}
                    <div style={{ marginTop: '2rem', paddingBottom: '1rem' }}>

                        <motion.div
                            className='AI-branded-div'
                            variants={variants}
                            animate=""
                            transition={{
                                duration: 1
                            }}
                            initial={{
                                opacity: 0,
                                x: -200

                            }}

                            whileInView="slidein"
                        // viewport={{ once: true }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h2 className='AI-branded'>AI-Themed</h2>
                            </div>
                        </motion.div>




                        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {
                                AIData.map((each_one, index) => {
                                    return (
                                        <Card
                                            className='card'
                                            key={index}
                                            style={{ border: '10px solid #911834' }}

                                        >
                                            <CardActionArea>
                                                <motion.div className='user-title-div'
                                                    variants={variants}
                                                    animate=" "
                                                    transition={{
                                                        duration: 1
                                                    }}
                                                    initial={{
                                                        opacity: 0,
                                                        y: 50

                                                    }}

                                                    whileInView="fadeInFromBottom"
                                                    viewport={{ once: true }}

                                                >

                                                    {
                                                        AImageLoaders[index] && (
                                                            <div className='spinner'
                                                                style={{}}>
                                                                <FidgetSpinner />
                                                            </div>
                                                        )
                                                    }


                                                    <Carousel fade interval={2000}>

                                                        {each_one.images.map((image, i) => (
                                                            <Carousel.Item>

                                                                <img
                                                                    onLoad={() => handleAImageLoad(index)}
                                                                    key={i}
                                                                    className="carou-img"
                                                                    src={image}

                                                                    draggable={false}
                                                                    onContextMenu={handleOnContextMenu}
                                                                />

                                                            </Carousel.Item>

                                                        ))}

                                                    </Carousel>


                                                </motion.div>
                                                <CardContent>

                                                    <div className='name-price-div '>
                                                        <Typography gutterBottom component="div"
                                                            className='name-of-shirt AI-shirt-name'>
                                                            {each_one.Name}
                                                        </Typography>

                                                        <Typography gutterBottom component="div"
                                                            className='price-of-shirt AI-shirt-price'>
                                                            {each_one.Price}
                                                        </Typography>
                                                    </div>


                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions >
                                                <div className='card-actions'>
                                                    <Button

                                                        onClick={() => (
                                                            openModalFunction(
                                                                each_one.modelImg,
                                                                each_one.Price,
                                                                each_one.color,
                                                                each_one.Name

                                                            )
                                                        )}

                                                        className='buy-btn aibrandedbuybtns'
                                                    >
                                                        BUY
                                                    </Button>
                                                    <span style={{ margin: '0.5rem' }}></span>

                                                    <Button

                                                        className='craft-btn'
                                                        onClick={() => (AICustomizedPressed(
                                                            each_one.color,
                                                            each_one.text,
                                                            each_one.artposition,
                                                            each_one.colorNameInEnglish,
                                                            each_one.decalColor,
                                                            each_one.AIArtWork,
                                                            each_one.Price
                                                        ))}
                                                    >
                                                        CUSTOMIZE
                                                    </Button>
                                                </div>
                                            </CardActions>
                                        </Card>


                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* emoji branded */}
                    <motion.div className='user-title-div'
                        variants={variants}
                        animate=""
                        transition={{ duration: 1 }}
                        initial={{
                            opacity: 0,
                            x: 200

                        }}
                        whileInView="slidein"
                    >
                        <h2 className='emoji-branded'>Emoji-Themed</h2>
                    </motion.div>
                    <div
                        className='card-div'
                    >
                        {
                            EmojiData.map((each_one, index) => {
                                return (
                                    <Card
                                        className='card'
                                        key={index}
                                        style={{ border: '10px solid orange', }}>
                                        <CardActionArea>
                                            <motion.div
                                                className='emoji-branded-div'
                                                variants={variants}
                                                animate=""
                                                transition={{
                                                    duration: 1
                                                }}
                                                initial={{
                                                    opacity: 0,
                                                    x: 0

                                                }}
                                                whileInView="fadeIn"
                                                viewport={{ once: true }}
                                            >

                                                {
                                                    EmojiimageLoaders[index] && (
                                                        <div className='spinner'
                                                            style={{}}>
                                                            <FidgetSpinner />
                                                        </div>
                                                    )
                                                }

                                                <Carousel fade interval={2000}>
                                                    {each_one.images.map((image, i) => (
                                                        <Carousel.Item>

                                                            <img
                                                                onLoad={() => handleEmojimageLoad(index)}
                                                                key={i}
                                                                // className=" img-fluid carou-img"
                                                                className="carou-img"
                                                                src={image}

                                                                draggable={false}
                                                                onContextMenu={handleOnContextMenu}
                                                            />

                                                        </Carousel.Item>

                                                    ))}
                                                </Carousel>
                                            </motion.div>
                                            <CardContent>

                                                <div className='name-price-div'>
                                                    <Typography gutterBottom component="div"
                                                        className='name-of-shirt emoji-shirt-name'>
                                                        {each_one.Name}
                                                    </Typography>

                                                    <Typography gutterBottom component="div" className='
                                                    price-of-shirt emoji-shirt-price'>
                                                        {each_one.Price}
                                                    </Typography>
                                                </div>


                                            </CardContent>


                                        </CardActionArea>

                                        <CardActions

                                            className='card-actions'
                                        >

                                            <div >
                                                <Button

                                                    onClick={() => (
                                                        openModalFunction(
                                                            each_one.modelImg,
                                                            each_one.Price,
                                                            each_one.color,
                                                            each_one.Name

                                                        )
                                                    )}

                                                    className='buy-btn emojibrandedbuybtns'
                                                >
                                                    Buy
                                                </Button>
                                                <span style={{ margin: '0.5rem' }}></span>

                                                {/* <Button

                                                    className='craft-btn'
                                                    onClick={() => (emojiCustomizedPressed(
                                                        each_one.color,
                                                        each_one.artWork,
                                                        each_one.text,
                                                        each_one.artposition,
                                                        each_one.colorNameInEnglish,
                                                        each_one.decalColor,
                                                        each_one.Price

                                                    ))}>
                                                    CUSTOMIZE
                                                </Button> */}
                                            </div>


                                        </CardActions>
                                    </Card>


                                )
                            })
                        }
                    </div>




                    {/* Tech branded */}
                    <div style={{ marginTop: '2rem', paddingBottom: '1rem' }}>

                        <motion.div className='user-title-div'
                            variants={variants}
                            animate=""
                            transition={{
                                duration: 1
                            }}
                            initial={{
                                opacity: 0,
                                x: 200

                            }}

                            whileInView="slidein"
                        // viewport={{ once: true }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <h2 className='Tech-branded'>Tech-Themed</h2>
                            </div>
                        </motion.div>


                        <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {
                                TechData.map((each_one, index) => {
                                    return (
                                        <Card
                                            className='card'
                                            key={index}
                                            style={{ border: '10px solid #614EAE' }}

                                        >
                                            <CardActionArea>
                                                <motion.div className='user-title-div'
                                                    variants={variants}
                                                    animate=""
                                                    transition={{
                                                        duration: 1
                                                    }}
                                                    initial={{
                                                        opacity: 0,
                                                        y: -50

                                                    }}


                                                    whileInView="fadeInFromBottom"
                                                    viewport={{ once: true }}
                                                >
                                                    {
                                                        TechImageLoaders[index] && (
                                                            <div className='spinner'
                                                                style={{}}>
                                                                <FidgetSpinner />
                                                            </div>
                                                        )
                                                    }

                                                    <Carousel fade interval={2000}>

                                                        {each_one.images.map((image, i) => (
                                                            <Carousel.Item>

                                                                <img
                                                                    onLoad={() => handleTechImageLoad(index)}
                                                                    key={i}
                                                                    className="carou-img"
                                                                    src={image}

                                                                    draggable={false}
                                                                    onContextMenu={handleOnContextMenu}
                                                                />

                                                            </Carousel.Item>

                                                        ))}

                                                    </Carousel>



                                                </motion.div>
                                                <CardContent>
                                                    <div className='name-price-div'>
                                                        <Typography gutterBottom
                                                            component="div" className='name-of-shirt Tech-shirt-name'>
                                                            {each_one.Name}
                                                        </Typography>

                                                        <Typography gutterBottom component="div"
                                                            className='price-of-shirt Tech-shirt-price'>
                                                            {each_one.Price}
                                                        </Typography>
                                                    </div>


                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions >
                                                <div className='card-actions'>
                                                    <Button

                                                        onClick={() => (
                                                            openModalFunction(
                                                                each_one.modelImg,
                                                                each_one.Price,
                                                                each_one.color,
                                                                each_one.Name
                                                            )
                                                        )}

                                                        className='buy-btn techbrandedbuybtns'
                                                    >
                                                        BUY
                                                    </Button>
                                                    <span style={{ margin: '0.5rem' }}></span>

                                                    <Button

                                                        className='craft-btn'

                                                        onClick={() => (TechCustomizedPressed(
                                                            each_one.color,
                                                            each_one.text,
                                                            each_one.artposition,
                                                            each_one.colorNameInEnglish,
                                                            each_one.decalColor,
                                                            each_one.TechArtWork,
                                                            each_one.Price
                                                        ))}

                                                    >
                                                        CUSTOMIZE
                                                    </Button>
                                                </div>
                                            </CardActions>
                                        </Card>


                                    )
                                })
                            }
                        </div>
                    </div>
                </div>




            }


            <OrderModal
                openModal={openModal}
                SelectedColorHexCode={'#E2E4E6'}
                imgSrc={imgsrc}
                handleCloseModal={handleCloseModal}
                emojiName={'Ecom'}
                chosenEmoji={'Ecom'}
                selectedTextColor={'transparent'}
                selectedText={'none'}
                selectedFontFamily={'none'}
                selectedFontSize={'none'}
                ecomPrice={price}
                nameOfShirt={nameOfShirt}
                buyingDirectly={buyingDirectly}

            />

        </div>
    )
}
