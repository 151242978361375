import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import hexToRgba from 'hex-to-rgba';
import { HumaansIconData } from '../humaansPath';
import { AiOutlineClose } from 'react-icons/ai';
import { HumaansImageDecal, emojiDecalIsSelected, decalImageIsSelected, HumaansImageDecalIsSelected } from '../store'
import Swal from 'sweetalert2'
import AILogo from '../assets/AI image.png'






export default function ImagesModal({ openImagesModal, handleCloseImagesModal, }) {



    const handleHumansImageSelected = (imagesrc, id) => {
        handleCloseImagesModal()
        HumaansImageDecal(imagesrc, id)
        emojiDecalIsSelected(false)
        decalImageIsSelected(false)
        HumaansImageDecalIsSelected(true)


    }

    const AIImageclicked = () => {
        handleCloseImagesModal()
        Swal.fire({
            title: 'AI Avatars',
            text: 'For Your AI-Generated Avatars Please WhatsApp +233508356802',
            imageUrl: AILogo,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',

        })

    }

    const handleOnContextMenu = (e) => {
        e.preventDefault();
    };



    return (

        <Modal
            open={openImagesModal}
            onClose={handleCloseImagesModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                overflow: 'scroll'
            }}

        >

            <Box
                // className='text-modal'
                style={{

                    borderRadius: '20px',
                    backgroundColor: 'white'

                }}

                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxHeight: '80%',
                    overflow: 'scroll',
                    boxShadow: 24,
                    p: 4,

                }}



            >

                <div
                    style={{ fontWeight: 'bold', textAlign: 'center', padding: '0.5rem' }}
                >
                    <h3> Elevate your style with some of our exclusive in-house artwork designs.</h3> </div>

                <div className='humansimagesmodal'>
                    <AiOutlineClose
                        className='cancel-modal'
                        color='#36454F'
                        onClick={handleCloseImagesModal}


                    />

                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

                        {
                            HumaansIconData.map((each_image, index) => {
                                return (


                                    <img src={each_image.imgsrc}
                                        style={{
                                            width: '10rem',
                                            height: '10rem',
                                            margin: '1rem',



                                        }}
                                        key={index}
                                        draggable={false}
                                        // onClick={() => (handleHumansImageSelected(each_image.imgsrc, each_image.id))}
                                        onClick={() =>
                                            index === 0
                                                ? AIImageclicked()
                                                : handleHumansImageSelected(each_image.imgsrc, each_image.id)
                                        }
                                        onContextMenu={handleOnContextMenu}
                                    />

                                )
                            })
                        }
                    </div>

                </div>
            </Box>

        </Modal >

    )
}
